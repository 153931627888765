import { useEffect, useState } from 'react';
import config from '../utils/env';

// 90 days
const TTL = 90 * 24 * 60 * 60 * 1000;
const LOCAL_STORAGE_KEY = 'referer';

type ValueWithExpiry = {
  value: string;
  expiry: number;
};

/**
 * Sets a value in the local storage with an expiry time.
 *
 * @param {string} key - The key to use for storing the value.
 * @param {string} value - The value to be stored.
 * @param {number} ttl - The time-to-live for the value in milliseconds.
 */
export function setValue(
  key: string,
  value: string,
  ttl: number,
  save = (k: string, v: string) => localStorage.setItem(k, v)
) {
  const now = new Date();
  const item: ValueWithExpiry = {
    value,
    expiry: now.getTime() + ttl,
  };
  save(key, JSON.stringify(item));
  localStorage.setItem(key, JSON.stringify(item));
}

/**
 * Retrieves a value with expiry from the local storage based on the provided key.
 *
 / @param {string} key - The key used to retrieve the value.
 * @returns {string | null} - The retrieved value or null if not found or expired.
 */
export function getValue(
  key: string,
  load = (k: string) => localStorage.getItem(k)
): string | null {
  const v = load(key);
  if (!v) return null;
  try {
    const item = JSON.parse(v) as ValueWithExpiry;
    const now = new Date();
    if (now.getTime() > item.expiry) {
      return null;
    }
    return item.value || null;
  } catch {
    return null;
  }
}

/**
 * Hook to get the callback form source
 * and the flow name
 * it decides which flow name to use
 * based on the query param `ref`
 * or the local storage `LOCAL_STORAGE_KEY`
 * if `ref` is `kb` or `LOCAL_STORAGE_KEY` is not expired (90 days)
 * it will use the kb flow name
 * when the ref is `kb`, it will refresh expire time for `LOCAL_STORAGE_KEY` in local storage
 */
export default function useSalesforceForm() {
  const [flowName, setFlowName] = useState<string | null>(null);
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const ref = searchParams.get('ref');
    if (ref) {
      setValue(LOCAL_STORAGE_KEY, ref.toLowerCase(), TTL);
    }
    const referer = getValue(LOCAL_STORAGE_KEY);
    if (referer === 'kb') {
      setFlowName('Web_New_Lead_Enquiry_for_Kiwi_Bank');
    } else {
      setFlowName('Web_New_Lead_Enquiry');
    }
  }, []);

  const callbackFormSrc = config.livingCallbackFormScriptSource;

  return {
    callbackFormSrc,
    flowName,
  };
}
