import { graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import Grid from 'styled-components-grid';
import { Margin, mr } from 'styled-components-spacing';

import insuranceAtAGlance from '../img/life-cover-options/insurance_at_a_glance.jpg';

import { PrimaryButton, SecondaryButton } from '@nib-components/button';
import colors from '@nib-components/colors';
import Copy, { Bold } from '@nib-components/copy';
import Form from '@nib-components/form';
import Heading from '@nib-components/heading';
import HeroPanel from '@nib-components/hero-panel';
import Link from '@nib-components/link';
import { colorTrueGreen, linkLoud } from '@nib-components/theme';
import Card from '@nib/card';
import { FormPanel } from '@nib/content-services-library/dist/cjs/components/form-panel';
import {
  CareGraphicIcon,
  CloseSystemIcon,
  CoffinGraphicIcon,
  HealthInsuranceGraphicIcon,
  HeartbeatGraphicIcon,
  SavingsGraphicIcon,
  TickSystemIcon,
} from '@nib/icons';
import { Box, Column, Columns, Container, Inline, Section, Stack } from '@nib/layout';

import IframeResizer from 'iframe-resizer-react';
import IconPoint from '../components/IconPoint';
import Layout from '../components/Layout';
import { ReducedGapCard } from '../components/styledComponents';
import { ContentfulDocument } from '../constructs/models';
import useCampaign from '../hooks/useCampaign';
import useSalesforceForm from '../hooks/useSalesforceForm';
import metrics from '../metrics';
import config from '../utils/env';
import { addColorStyleToAnchorTags } from '../utils/html-string';

const title = 'Life Insurance and Living Insurance Quote';
const description =
  'New Zealanders can get peace of mind if they pass away or can’t work anymore with life insurance or illness and income protection cover from nib.';

type DocumentResult = {
  edges: {
    node: {
      document: ContentfulDocument;
    };
  }[];
};

interface ImageShape {
  file: {
    url: string;
  };
}

interface HtmlMarkdownShape {
  childMarkdownRemark: {
    html: string;
  };
}
interface RawAndHtmlMarkdownShape {
  childMarkdownRemark: {
    html: string;
    rawMarkdownBody: string;
  };
}

interface HeroImagesShape {
  desktop: ImageShape;
  desktop2x4000x1200: ImageShape;
  mobile2x800x300: ImageShape;
  mobile400x150: ImageShape;
  tablet2x1600x580: ImageShape;
  tablet800x290: ImageShape;
}

interface NodeShape {
  title: string;
  startDate: string;
  heroTitle: string;
  heroCopy: RawAndHtmlMarkdownShape;
  leftButtonText: string;
  rightButtonText: string;
  legal: RawAndHtmlMarkdownShape;
  campaignCode: string;
  heroImage: HeroImagesShape;
  importantThingsToKnow: HtmlMarkdownShape;
}

type LifeCoverOptionsPageResult = {
  edges: {
    node: NodeShape;
  }[];
};

type GraphQLResult = {
  temporaryAccidentalDeathCover: DocumentResult;
  lifePolicyDocument: DocumentResult;
  factSheetUrl: DocumentResult;
  lifeCoverOptionsPage: LifeCoverOptionsPageResult;
};

interface LifeCoverOptionsProps {
  data: GraphQLResult;
}

const InsuranceGlanceImg = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const LifeCoverOptions = ({ data }: LifeCoverOptionsProps): JSX.Element => {
  const { callbackFormSrc, flowName } = useSalesforceForm();
  const schema = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    '@id': 'WebPage',
    identifier: 'https://www.nib.co.nz/life-cover-options',
    url: 'https://www.nib.co.nz/life-cover-options',
    description:
      'New Zealanders can get peace of mind if they pass away or can’t work anymore with life insurance or illness and income protection cover from nib.',
    name: ['Life Insurance and Living Insurance Quote | nib'],
    isPartOf: 'https://www.nib.co.nz',
  };
  const JSONschema = JSON.stringify(schema);
  const contentfulData = data.lifeCoverOptionsPage.edges;
  const campaignInfo = useCampaign(contentfulData.map((d) => d.node));

  return (
    <Layout>
      <div>
        <Helmet>
          <title>{title} | nib</title>
          <meta name="description" content={description} />
          <script type="application/ld+json">{JSONschema}</script>
        </Helmet>
        <div>
          {campaignInfo ? (
            <HeaderSection
              title={campaignInfo.heroTitle}
              copy={campaignInfo.heroCopy}
              images={campaignInfo.heroImage}
              legalCopy={campaignInfo.legal}
              leftButtonText={campaignInfo.leftButtonText}
              rightButtonText={campaignInfo.rightButtonText}
            />
          ) : (
            <div style={{ minHeight: 500 }}></div>
          )}
        </div>
        <LifeAndLivingInsuranceAtAGlance />
        <CompareLifeAndLivingPlans data={data} />
        <LifeAndLivingEstimatorTool data={data} />
        <LifeAndLivingInsuranceOptions data={data} />
        <ArrangeACallBackSection src={callbackFormSrc} flowName={flowName} />
        <div role="section" id="important-things-to-know">
          {campaignInfo && <ThingsToKnowSection content={campaignInfo?.importantThingsToKnow} />}
        </div>
      </div>
    </Layout>
  );
};

const LifeAndLivingInsuranceAtAGlance = (): JSX.Element => {
  return (
    <Section role="section">
      <Stack space={{ xs: 2, md: 4 }}>
        <Box display="flex" justifyContent="center">
          <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="h1">
            Life Insurance and living insurance at a glance
          </Heading>
        </Box>
        <Box display="flex" justifyContent="center">
          <Copy measure={false}>
            Our life insurance and living insurance options offer flexible choices for cover so that
            you can best protect what's important to you.
          </Copy>
        </Box>
        <Box display="flex" justifyContent="center">
          <InsuranceGlanceImg
            src={insuranceAtAGlance}
            alt="Life insurance and living insurance options at nib"
          />
        </Box>
      </Stack>
    </Section>
  );
};

const CompareLifeAndLivingPlans = ({ data }: LifeCoverOptionsProps): JSX.Element => {
  const factSheetUrl = data.factSheetUrl?.edges[0]?.node?.document?.file.url;
  return (
    <Section background="warmWhite60" role="section">
      <Stack space={{ xs: 2, md: 4 }}>
        <Copy measure={false}>
          Each of our life insurance & living insurance options provides financial support following
          a significant event or trauma. You can choose from the following options:
        </Copy>
        <Margin top={6} bottom={6}>
          <Stack space={{ xs: 4, md: 6 }}>
            <IconPoint
              icon={CareGraphicIcon}
              largeText={false}
              description={
                <span>
                  <Bold color="trueGreen">Life insurance</Bold>
                  <Copy measure={false}>
                    This pays a lump sum of money if you die or are diagnosed as terminally ill and
                    are expected to die within the next 12 months.
                  </Copy>
                </span>
              }
            />
            <IconPoint
              icon={HealthInsuranceGraphicIcon}
              largeText={false}
              description={
                <span>
                  <Bold color="trueGreen">Living insurance</Bold>
                  <Copy measure={false}>
                    This provides options for financial support following illness and injury.
                  </Copy>
                </span>
              }
            />
            <IconPoint
              icon={HeartbeatGraphicIcon}
              largeText={false}
              indent={2}
              description={
                <span>
                  <Bold color="trueGreen">Serious Illness Trauma</Bold>
                  <Copy measure={false}>
                    This cover pays a lump sum to you if you're diagnosed with a medical condition
                    defined in the policy, such as severe cancer or a major heart attack.
                  </Copy>
                </span>
              }
            />
            <IconPoint
              icon={SavingsGraphicIcon}
              largeText={false}
              indent={2}
              description={
                <span>
                  <Bold color="trueGreen">Income Protection Illness</Bold>
                  <Copy measure={false}>
                    This cover pays you an agreed monthly amount if you're unable to work because of
                    illness, providing you were previously working at least 25 hours per week for a
                    single employer.
                  </Copy>
                </span>
              }
            />
            <IconPoint
              icon={CoffinGraphicIcon}
              largeText={false}
              description={
                <span>
                  <Bold color="trueGreen">Funeral cover</Bold>
                  <Copy measure={false}>
                    With any of the above options, you also get complimentary Funeral Cover. This
                    pays a lump sum of $15,000 to help with funeral costs if you die or are
                    diagnosed as terminally ill and are expected to die within the next 12 months.
                  </Copy>
                </span>
              }
            />
          </Stack>
        </Margin>
        <Inline space={4}>
          <PrimaryButton href={factSheetUrl}>Download Fact Sheet</PrimaryButton>
        </Inline>
      </Stack>
    </Section>
  );
};

const FixedWidth32px = styled.div`
  width: 32px;
  ${mr(1)};
  padding-top: 2px;
  text-align: center;
`;

const CheckItem = ({ children }): JSX.Element => {
  return (
    <Grid wrap={false}>
      <Grid.Unit size="min">
        <FixedWidth32px>
          <TickSystemIcon size="xs" fill="trueGreen" />
        </FixedWidth32px>
      </Grid.Unit>
      <Grid.Unit size="max">
        <Copy measure={false}>{children}</Copy>
      </Grid.Unit>
    </Grid>
  );
};
const CrossItem = ({ children }): JSX.Element => {
  return (
    <Grid wrap={false}>
      <Grid.Unit size="min">
        <FixedWidth32px>
          <CloseSystemIcon size="xs" color={colors.kevin} />
        </FixedWidth32px>
      </Grid.Unit>
      <Grid.Unit size="max">
        <Copy measure={false}>{children}</Copy>
      </Grid.Unit>
    </Grid>
  );
};

const LifeAndLivingEstimatorTool = ({ data }: LifeCoverOptionsProps): JSX.Element => {
  const [referralCode, setReferralCode] = useState('');
  const isBrowser = () => typeof window !== 'undefined';

  useEffect(() => {
    if (isBrowser()) {
      if (window.location.search.startsWith('?ref=') && window.location.search.length > 5) {
        setReferralCode(window.location.search.substring(5));
      } else {
        setReferralCode('');
      }
    }
  }, [isBrowser() && window.location.search]);

  return (
    <Section id="estimator-tool">
      <Stack space={{ xs: 2, md: 4 }}>
        <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="h2">
          Life & Living Insurance Estimator
        </Heading>
        <Copy measure={false}>
          Working out what kind of cover best suits your situation can be tricky. If you don’t quite
          know where to start, we’ve made things easy with our Estimator tool.
        </Copy>
        <Copy measure={false}>
          This estimator provides a simple starter for considering what type of life insurance or
          living insurance may be right for you and how much cover you might need, based on your
          answers to some general questions and choices you make about the level of cover you would
          want.
        </Copy>
        <IframeResizer
          src={
            referralCode.length === 0
              ? config.lifeEstimatorUrl
              : `${config.lifeEstimatorUrl}?ref=${referralCode}`
          }
          style={{ width: '1px', minWidth: '100%', border: 'none' }}
          scrolling={false}
          onScroll={() => true}
          heightCalculationMethod="lowestElement"
        />
      </Stack>
    </Section>
  );
};

const LifeAndLivingInsuranceOptions = ({ data }: LifeCoverOptionsProps): JSX.Element => {
  const policyDocumentUrl = data.lifePolicyDocument?.edges[0]?.node?.document.file.url;

  return (
    <Section background="trueGreen">
      <Stack space={{ xs: 2, md: 4 }}>
        <Heading color="clearWhite" size={{ sm: 4, md: 3, lg: 2 }} component="h2">
          What are your options?
        </Heading>
        <Columns space={{ xs: 4, md: 4, lg: 6 }} collapseBelow="md">
          <Column>
            <ReducedGapCard>
              <Card.Content title="Life cover">
                <Copy measure={false}>
                  Provides a lump sum for your loved ones after you die or if you’re diagnosed with
                  a terminal illness and are expected to die in the next 12 months.
                </Copy>
                <Copy measure={false}>
                  <Bold>How much do you need?</Bold>
                </Copy>
                <Copy measure={false}>
                  If you were to die, would there be enough money to cover your:
                  <ul>
                    <li>family's immediate living costs?</li>
                    <li>existing debts, like mortgages, credit cards and personal loans?</li>
                    <li>funeral expenses?</li>
                    <li>future expenses like education for your children?</li>
                  </ul>
                </Copy>
                <Copy measure={false}>
                  <Bold>Maximum cover amount:</Bold>
                </Copy>
                <Copy measure={false}>You can apply for any amount of cover.</Copy>
                <Stack space={5}>
                  <Stack space={3}>
                    <CheckItem>Money is paid to you, your beneficiary, or your estate</CheckItem>
                    <CheckItem>One lump sum payment</CheckItem>
                    <CheckItem>Must be 18 - 70 years old to apply</CheckItem>
                  </Stack>
                  <Stack space={3}>
                    <CrossItem>
                      No cover for passing away from self-inflicted harm during the first 13 months
                      of cover
                    </CrossItem>
                  </Stack>
                </Stack>
                <Copy measure={false}>
                  Please read the{' '}
                  <Link href={policyDocumentUrl}>Life & Living policy document</Link>
                </Copy>
              </Card.Content>
            </ReducedGapCard>
          </Column>
          <Column>
            <ReducedGapCard>
              <Card.Content title="Serious Illness Trauma cover">
                <Copy measure={false}>
                  Provides a lump sum if you're diagnosed with a defined serious medical condition.
                </Copy>
                <Copy measure={false}>
                  <Bold>How much do you need?</Bold>
                </Copy>
                <Copy measure={false}>
                  If you were unable to work because of illness, would you have enough money to
                  cover:
                  <ul>
                    <li>your current income?</li>
                    <li>the difference between your current income and a disability benefit?</li>
                    <li>your family's immediate living costs?</li>
                    <li>future expenses related to your illness?</li>
                  </ul>
                </Copy>
                <Copy measure={false}>
                  <Bold>Maximum cover amount:</Bold>
                </Copy>
                <Copy measure={false}>$1 million.</Copy>
                <Stack space={5}>
                  <Stack space={3}>
                    <CheckItem>Money paid to you</CheckItem>
                    <CheckItem>
                      Full or partial payment of the amount insured depending on the severity of the
                      specific illness
                    </CheckItem>
                    <CheckItem>Must be 18 - 60 years old to apply</CheckItem>
                  </Stack>
                  <Stack space={3}>
                    <CrossItem>No cover for any defined cancers in first 90 days</CrossItem>
                    <CrossItem>
                      No cover if you die within 14 days of the diagnosis of a medical condition
                      specifically defined in the policy
                    </CrossItem>
                  </Stack>
                </Stack>
                <Copy measure={false}>
                  Please read the{' '}
                  <Link href={policyDocumentUrl}>Life & Living policy document</Link>
                </Copy>
              </Card.Content>
            </ReducedGapCard>
          </Column>
          <Column>
            <ReducedGapCard>
              <Card.Content title="Income Protection Illness cover">
                <Copy measure={false}>
                  Provides a monthly payment if you can’t work due to illness.
                </Copy>
                <Copy measure={false}>
                  <Bold>How much do you need?</Bold>
                </Copy>
                <Copy measure={false}>
                  If you were unable to work because of illness, would you have enough money to
                  cover:
                  <ul>
                    <li>your current income?</li>
                    <li>the difference between your current income and a disability benefit?</li>
                    <li>your family's immediate living costs?</li>
                    <li>future expenses related to your illness?</li>
                  </ul>
                </Copy>
                <Copy measure={false}>
                  <Bold>Maximum cover amount:</Bold>
                </Copy>
                <Copy measure={false}>
                  55% of your gross income, up to a maximum of $6,000 per month.
                </Copy>
                <Stack space={5}>
                  <Stack space={3}>
                    <CheckItem>Money paid to you</CheckItem>
                    <CheckItem>Two or five year cover period</CheckItem>
                    <CheckItem>Must be 18 - 60 years old to apply</CheckItem>
                    <CheckItem>
                      Must be employed for at least 25 hours per week for a single employer
                    </CheckItem>
                  </Stack>
                  <Stack space={3}>
                    <CrossItem>No cover during the first 30 days</CrossItem>
                    <CrossItem>
                      Monthly payment may be reduced by your other income, including other payments
                      such as a sickness or ACC benefit
                    </CrossItem>
                  </Stack>
                </Stack>
                <Copy measure={false}>
                  Please read the{' '}
                  <Link href={policyDocumentUrl}>Life & Living policy document</Link>
                </Copy>
              </Card.Content>
            </ReducedGapCard>
          </Column>
        </Columns>
      </Stack>
    </Section>
  );
};

const ArrangeACallBackSection = ({
  src,
  flowName,
}: {
  src: string;
  flowName: string | null;
}): JSX.Element => {
  // The script seems to only work for certain whitelsited(?) hostnames
  return (
    <Box role="section" id="book-a-call">
      <Box paddingHorizontal={{ xs: 4, md: 6, lg: 8 }}>
        <Container>
          <div style={{ display: 'none' }}>{flowName}</div>
          {flowName && (
            <Form id="programmeEnquiryForm" name="programmeEnquiryForm" formMode="white">
              <FormPanel
                variant="salesforceFlow"
                scriptSrc={src}
                appName="c:flowApp"
                componentName="lightning:flow"
                domLocator="flowContainer"
                flowName={flowName}
                attributes={{ class: 'myCustomClass' }}
              />
            </Form>
          )}
        </Container>
      </Box>
    </Box>
  );
};

interface ContentSectionProps {
  html: string;
  className?: string;
}

const StyledCopy = styled.div`
  a {
    color: ${linkLoud};
  }
}`;

const ContentSection = (props: ContentSectionProps): JSX.Element => {
  const { html } = props;
  return (
    <Container className={props.className}>
      <Copy measure={false}>
        <StyledCopy dangerouslySetInnerHTML={{ __html: html }} />
      </Copy>
    </Container>
  );
};

type HeaderSectionProps = {
  title: string;
  copy: RawAndHtmlMarkdownShape;
  images: HeroImagesShape;
  legalCopy: RawAndHtmlMarkdownShape;
  leftButtonText: string;
  rightButtonText: string;
};

const HeaderSection = ({
  title,
  copy,
  images,
  legalCopy,
  leftButtonText,
  rightButtonText,
}: HeaderSectionProps): JSX.Element => (
  <HeroPanel
    variation="condensed"
    images={{
      desktop: images?.desktop?.file?.url,
      desktop2x: images?.desktop2x4000x1200?.file?.url,
      mobile: images?.mobile400x150?.file?.url,
      mobile2x: images?.mobile2x800x300?.file?.url,
      tablet: images?.tablet800x290?.file?.url,
      tablet2x: images?.tablet2x1600x580?.file?.url,
    }}
  >
    {title && (
      <Heading component="p" size={{ xs: 2, md: 1 }} fontType="serif" color="trueGreen">
        {title ? title : 'Life & Living Insurance options'}
      </Heading>
    )}
    <ContentSection html={copy?.childMarkdownRemark?.html} />
    <Inline space={4} collapseBelow="md" align="left" verticalAlign="bottom">
      // write code to make the text of the buttons dynamic
      <PrimaryButton href={'#estimator-tool'}>{leftButtonText}</PrimaryButton>
      <SecondaryButton href={'#book-a-call'}>{rightButtonText}</SecondaryButton>
    </Inline>
    <Copy>
      <StyledCopy dangerouslySetInnerHTML={{ __html: legalCopy?.childMarkdownRemark?.html }} />
    </Copy>
  </HeroPanel>
);

const ThingsToKnowSection = ({ content }: { content: HtmlMarkdownShape }): JSX.Element => {
  const ContentSectionStyled = styled(ContentSection)`
    word-break: break-word;
    .life-cover-options-h2 {
      color: ${colorTrueGreen};
      font-family: 'Buenos Aires', 'Georgia', serif;
      font-size: 1.5rem;
      font-weight: bold;
      letter-spacing: -1px;
      line-height: 1.333;

      @media (min-width: 800px) {
        font-size: 2.25rem;
      }
    }
    .life-cover-options-h3 {
      color: ${colorTrueGreen};
      font-size: 1.25rem;
    }
    ul > li {
      margin-bottom: 1rem;
    }
  `;
  return (
    <Section role="section" background="sageGreen60">
      <Stack space={{ xs: 4, md: 6 }}>
        <ContentSectionStyled
          html={addColorStyleToAnchorTags(content?.childMarkdownRemark?.html)}
        />
      </Stack>
    </Section>
  );
};

export const DocumentsQuery = graphql`
  query ComparelifePlansDocumentsQuery {
    temporaryAccidentalDeathCover: allContentfulDocument(
      filter: { name: { eq: "Temporary Accidental Death Cover" } }
      limit: 1
    ) {
      edges {
        node {
          document {
            file {
              fileName
              url
            }
          }
        }
      }
    }
    lifePolicyDocument: allContentfulDocument(
      filter: { name: { eq: "Life & Living Insurance Policy Document" } }
      limit: 1
    ) {
      edges {
        node {
          document {
            file {
              fileName
              url
            }
          }
        }
      }
    }
    factSheetUrl: allContentfulDocument(
      filter: { name: { eq: "Life and Living Insurance Fact Sheet" } }
      limit: 1
    ) {
      edges {
        node {
          document {
            file {
              fileName
              url
            }
          }
        }
      }
    }
    lifeCoverOptionsPage: allContentfulLifeCoverOptionsPage(
      sort: { fields: startDate, order: DESC }
      limit: 2
    ) {
      edges {
        node {
          title
          heroTitle
          heroCopy {
            childMarkdownRemark {
              html
            }
          }
          heroImage {
            desktop {
              file {
                url
              }
            }
            desktop2x4000x1200 {
              file {
                url
              }
            }
            mobile2x800x300 {
              file {
                url
              }
            }
            mobile400x150 {
              file {
                url
              }
            }
            tablet2x1600x580 {
              file {
                url
              }
            }
            tablet800x290 {
              file {
                url
              }
            }
          }
          leftButtonText
          rightButtonText
          legal {
            childMarkdownRemark {
              html
            }
          }
          campaignCode
          startDate
          importantThingsToKnow {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;

export default metrics({ pageName: 'life-cover-options' })(LifeCoverOptions);
